import API from '@/shared/helpers/API.js';
import {getLocalDateFromServer} from "@/shared/helpers/dates";

export const userStoreActions = {
	init(self, redirectUrlAuth = null, kickIfNotLogged = true) {

		let fullRedirectUrl = URL_HTML + "/console/login/";
		if(redirectUrlAuth) {
			fullRedirectUrl += "?redirect_url_auth=" + encodeURIComponent(redirectUrlAuth);
		}

		let oauthToken = sessionStorage.getItem('oauth_token');
		if(!oauthToken || oauthToken === "") {
			let refreshToken = localStorage.getItem('refresh_token');
			if(refreshToken && refreshToken !== "") {
				API.init(URL_HTML, fullRedirectUrl).loginUserRefreshToken(refreshToken, function (response) {

					if ("access_token" in response) {
						sessionStorage.setItem("oauth_token", response.access_token);
						API.init().oauthToken = response.access_token;
						if (!API.init().oauthToken || API.init().oauthToken === "") {
							window.location.replace(fullRedirectUrl);
						}
						self.refreshUserAccount();
					} else {
						localStorage.removeItem("refresh_token");
						if(kickIfNotLogged) {
							window.location.replace(fullRedirectUrl);
						}
					}

				});
			} else {
				if(kickIfNotLogged) {
					window.location.replace(fullRedirectUrl);
				}
			}

		} else {

			API.init(URL_HTML, fullRedirectUrl).oauthToken = oauthToken;
			self.refreshUserAccount();

		}

	},
	normalizeBusiness(business) {
		if(!business) {
			return null;
		}
		business.link = business.link || null;
		business.plan_cycle = business.plan_cycle || null;
		business.counter_shortcut = business.counter_shortcut || null;
		business.notification_sound = business.notification_sound || null;
		business.payout_interval = business.payout_interval || null;
		business.registrationDate = getLocalDateFromServer(business.registration_date);

		business.webhooks = business.webhooks ? this.normalizeWebhooks(business.webhooks) : [];
		business.qromopay = business.qromopay ? this.normalizeQromopay(business.qromopay) : [];

		business.customer_completable_order = Number(business.customer_completable_order);
		business.satispay_status = Number(business.satispay_status);
		business.menu_language_id = Number(business.menu_language_id);
		business.delivery_courier_pickup_delay = business.delivery_courier_pickup_delay ? Number(business.delivery_courier_pickup_delay) : 1;

		business.delivery_courier_free_shipping = business.delivery_courier_free_shipping ? Number(business.delivery_courier_free_shipping) : null;
		business.address_id = business.address_id ? Number(business.address_id) : null;
		business.address = business.address ? this.normalizeAddress(business.address) : null;
		business.legal_address_id = business.legal_address_id ? Number(business.legal_address_id) : null;
		business.legal_address = business.legal_address ? this.normalizeAddress(business.legal_address) : null;
		business.min_order = business.min_order ? Number(business.min_order) : null;
		business.open_balance = business.open_balance ? Number(business.open_balance) : null;
		business.stripe_balance_available = business.stripe_balance_available ? Number(business.stripe_balance_available) : null;
		business.stripe_balance_pending = business.stripe_balance_pending ? Number(business.stripe_balance_pending) : null;
		business.payout_delay = business.payout_delay ? Number(business.payout_delay) : null;
		business.takeaway_costs = business.takeaway_costs ? Number(business.takeaway_costs) : null;
		business.takeaway_min_order = business.takeaway_min_order ? Number(business.takeaway_min_order) : null;
		business.takeaway_future_days = business.takeaway_future_days ? Number(business.takeaway_future_days) : null;
		business.delivery_distance = business.delivery_distance ? Number(business.delivery_distance) : null;
		business.delivery_costs = business.delivery_costs ? Number(business.delivery_costs) : null;
		business.delivery_min_order = business.delivery_min_order ? Number(business.delivery_min_order) : null;
		business.delivery_time = business.delivery_time ? Number(business.delivery_time) : null;
		business.delivery_future_days = business.delivery_future_days ? Number(business.delivery_future_days) : null;
		business.preparation_time = business.preparation_time ? Number(business.preparation_time) : null;
		business.integration_id = business.integration_id ? Number(business.integration_id) : null;
		business.default_menu_id = business.default_menu_id ? Number(business.default_menu_id) : null;
		business.stripe_owner = business.stripe_owner ? Number(business.stripe_owner) : null;
		business.delivery_courier_free_shipping = business.delivery_courier_free_shipping ? Number(business.delivery_courier_free_shipping) : null;
		business.default_stripe_location_id = business.default_stripe_location_id ? Number(business.default_stripe_location_id) : null;
		business.most_sold_quantity = business.most_sold_quantity ? Number(business.most_sold_quantity) : null;
		business.buffer_time = business.buffer_time ? Number(business.buffer_time) : null;
		business.apifattura_id = business.apifattura_id ? Number(business.apifattura_id) : null;
		business.apiscontrino_id = business.apiscontrino_id ? Number(business.apiscontrino_id) : null;
		business.counter_manual_code = business.counter_manual_code ? business.counter_manual_code : null;

		business.cross_selling = business.cross_selling === "1";
		business.cross_selling_popup = business.cross_selling_popup === "1";
		business.buffer_customers = business.buffer_customers === "1";
		business.autocomplete_payed_counter = business.autocomplete_payed_counter === "1";
		business.show_counter_numpad = business.show_counter_numpad === "1";
		business.counter_courses = business.counter_courses === "1";
		business.counter_manual = business.counter_manual === "1";
		business.allergens_filter = business.allergens_filter === "1";
		business.disable_feedbacks = business.disable_feedbacks === "1";
		business.private_feedbacks = business.private_feedbacks === "1";
		business.queue_active_menu = business.queue_active_menu === "1";
		business.queue_active_summary = business.queue_active_summary === "1";
		business.accept_cash = business.accept_cash === "1";
		business.accept_bitcoin = business.accept_bitcoin === "1";
		business.accept_cards = business.accept_cards === "1";
		business.accept_klarna = business.accept_klarna === "1";
		business.accept_voucherly = business.accept_voucherly === "1";
		business.accept_satispay = business.accept_satispay === "1";
		business.warehouse = business.warehouse === "1";
		business.enabled_box_email_cash = business.enabled_box_email_cash === "1";
		business.sms_qr_table = business.sms_qr_table === "1";
		business.sms_qr_seat = business.sms_qr_seat === "1";
		business.sms_qr_area = business.sms_qr_area === "1";
		business.sms_qr_name = business.sms_qr_name === "1";
		business.sms_qr_single_table = business.sms_qr_single_table === "1";
		business.autocomplete_old_orders = business.autocomplete_old_orders === "1";
		business.allow_request_invoice = business.allow_request_invoice === "1";
		business.coupons = business.coupons === "1";
		business.takeaway = business.takeaway === "1";
		business.takeaway_cash = business.takeaway_cash === "1";
		business.delivery = business.delivery === "1";
		business.delivery_cash = business.delivery_cash === "1";
		business.delivery_courier = business.delivery_courier === "1";
		business.force_privacy = business.force_privacy === "1";
		business.show_order_qr = business.show_order_qr === "1";
		business.enabled_search = business.enabled_search === "1";
		business.read_mode = business.read_mode === "1";
		business.commissions_business = business.commissions_business === "1";
		business.config_menu = business.config_menu === "1";
		business.config_tables = business.config_tables === "1";
		business.config_payments = business.config_payments === "1";
		business.config_settings = business.config_settings === "1";
		business.plan_plugin_order_pay = business.plan_plugin_order_pay === "1";
		business.plan_plugin_delivery = business.plan_plugin_delivery === "1";
		business.plan_plugin_takeaway = business.plan_plugin_takeaway === "1";
		business.plan_plugin_pos = business.plan_plugin_pos === "1";
		business.plan_plugin_counter = business.plan_plugin_counter === "1";
		business.plan_plugin_pay_bill = business.plan_plugin_pay_bill === "1";
		business.hide_prices = business.hide_prices === "1";
		business.hide_notes = business.hide_notes === "1";
		business.print_cash_unaccepted = business.print_cash_unaccepted === "1";
		business.pos = business.pos === "1";
		business.btcpay_active = business.btcpay_active === "1";
		business.block_orders = business.block_orders === "1";
		business.deleted = business.deleted === "1";
		business.stripe_trial = business.stripe_trial === "1";
		business.counter_hide_secondary = business.counter_hide_secondary === "1";
		business.counter_hide_subtotal = business.counter_hide_subtotal === "1";
		business.counter_images = business.counter_images === "1";
		business.counter_direct_pos = business.counter_direct_pos === "1";
		business.counter_variations_limits = business.counter_variations_limits === "1";

		business.dismissed_funnel = business.dismissed_funnel === "1";

		return business;
	},
	initDarkMode(self) {
		let paramDarkMode = localStorage.getItem('darkMode');
		if(paramDarkMode === null) {
			localStorage.setItem('darkMode', '0');
			paramDarkMode = '0';
		}
		self.darkMode = paramDarkMode === '1';
		if(paramDarkMode === '2') {
			let now = new Date();
			let hours = now.getHours();
			self.darkMode = hours >= 18 || hours < 6;
		}
		document.documentElement.classList[self.darkMode ? 'add' : 'remove']('dark');
		document.documentElement.style.setProperty('--status-color-qr', self.darkMode ? '#1783f8' : '#0752A3');
		document.documentElement.style.setProperty('--status-color-delivery', self.darkMode ? '#1bd5fb' : '#03A5CB');
		document.documentElement.style.setProperty('--status-color-takeaway', self.darkMode ? '#b445ff' : '#7b03cb');
		document.documentElement.style.setProperty('--status-color-counter', self.darkMode ? '#ff8539' : '#e36e28');
	},
	normalizeAddress(address) {
		address.address = address.address ? address.address : null;
		address.address_id = address.address_id ? Number(address.address_id) : null;
		address.user_id = address.user_id ? Number(address.user_id) : null;
		address.lat = address.lat ? Number(address.lat) : null;
		address.lng = address.lng ? Number(address.lng) : null;
		address.civico = address.civico || null;
		address.comune = address.comune || null;
		address.details = address.details || null;
		address.provincia = address.provincia || null;
		address.provincia_codice = address.provincia_codice || null;
		address.regione = address.regione || null;
		address.via = address.via || null;
		address.zip = address.zip || null;
		address.date = address.date || null;
		address.deleted = address.deleted === '1';

		return address;
	},
	normalizeWebhooks(webhooks) {
		if(!webhooks || !webhooks.length) {
			return [];
		}
		return webhooks.map(w => {
			w.business_webhook_id = w.business_webhook_id ? Number(w.business_webhook_id) : null;
			w.name = w.name || null;
			w.url = w.url || null;
			w.auth = w.auth || null;
			return w;
		});
	},
	normalizeQromopay(qromopay) {
		if(!qromopay || !qromopay.length) {
			return [];
		}
		return qromopay.map(w => {
			w.business_qromopay_id = w.business_qromopay_id ? Number(w.business_qromopay_id) : null;
			w.name = w.name || null;
			w.webhook_url = w.webhook_url || null;
			w.secret_key = w.secret_key || null;
			return w;
		});
	},
	normalizeUser(user) {
		if(!user) {
			return null;
		}
		let returningUser = {};
		returningUser.credit = user.credit ? Number(user.credit) : null;
		returningUser.email = user.email || null;
		returningUser.name = user.name || null;
		returningUser.phone = user.phone || null;
		returningUser.stripe_customer = user.stripe_customer || null;
		returningUser.stripe_trial = user.stripe_trial === "1";
		returningUser.tfa_secret = user.tfa_secret || null;
		returningUser.user_id = user.user_id ? Number(user.user_id) : null;

		return returningUser;
	},
	refreshUserAccount(self, callback = null) {
		if(typeof window.userData !== "undefined" && window.userData && window.userData.result === "OK" && window.userData.user && window.userData.businesses) {
			this.postRefreshUserAccount(self, window.userData, callback);
			window.userData = null;
		} else {
			API.init().userAccount((response) => {
				if (response && response.result === "OK" && response.user && response.businesses) {
					this.postRefreshUserAccount(self, response, callback);
				}
			});
		}
	},
	postRefreshUserAccount(self, response, callback = null) {
		self.user = response.user;
		let presetBusinessId = null;
		let getParamBusinessId = null;
		let get_params = JSON.parse(sessionStorage.getItem('get_params'));
		if(get_params && get_params.business_id) {
			getParamBusinessId = get_params.business_id;
		}

		for(let businessId in response.businesses) {
			self.normalizeBusiness(response.businesses[businessId]);
			if(getParamBusinessId && !presetBusinessId && businessId === getParamBusinessId) {
				presetBusinessId = businessId;
			}
		}
		self.businesses = response.businesses;

		let keys = Object.keys(self.businesses);
		self.noBusinesses = keys.length === 0;

		let storageBusinessId = localStorage.getItem('current-business-id');
		if(!presetBusinessId) {
			if (!self.businesses[storageBusinessId]) {
				storageBusinessId = null;
			}
		}

		let id = presetBusinessId || storageBusinessId || (keys.length ? keys[0] : null);
		if(!id || !self.businesses[id]) { return }
		self.currBusinessId = id;
		localStorage.setItem('current-business-id', id);

		callback && callback();
	},
	refreshBusiness(self, businessId, callback = null) {
		API.init().getBusiness(businessId, function (response) {
			if (response && response.result === "OK") {
				self.normalizeBusiness(response.business);
				self.businesses[businessId] = response.business;
				callback && callback();
			}
		});
	},
	getBusinessUsers(self, callback = null) {
		if(!self.currBusinessId) {
			return;
		}
		self.businessUsers = {};
		API.init().getBusinessUsers(self.currBusinessId, function (r) {
			if (r && r.users && r.users.length) {
				//group users by user_id
				for (let i = 0; i < r.users.length; i++) {
					let currUser = r.users[i];
					if (!self.businessUsers[currUser.user_id]) {
						self.businessUsers[currUser.user_id] = currUser;
						self.businessUsers[currUser.user_id].permission = [currUser.permission];
					} else {
						self.businessUsers[currUser.user_id].permission.push(currUser.permission);
					}
				}
			}
			if(callback) {
				callback(self.businessUsers);
			}
		});
	},
	getBusinessLocation(self, callback = null) {
		if(self.default_stripe_location_id) {
			callback && callback(self.default_stripe_location_id, null);
		} else {
			API.init().getBusinessLocation(self.currBusinessId, function (r) {
				if (r && r.result === "OK" && r.location_id) {
					self.default_stripe_location_id = r.location_id;
					callback && callback(r.location_id, null);
				} else {
					if(r && r.result === "KO" && r.details) {
						callback && callback(null, r.details);
					} else {
						callback && callback(null, null);
					}
				}
			});
		}
	},
}

export const userStoreGetters = {
	currBusiness(state) {
		if(!state.currBusinessId || !state.businesses) {
			return null;
		}
		return state.businesses[state.currBusinessId];
	},
	currPermissions(state) {
		if(!state || !state.currBusiness || !state.currBusiness.permissions || !state.currBusiness.permissions.length) {
			return [];
		}
		return state.currBusiness.permissions.map(p=>p.permission);
	}
}
