import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useEpsonStore, useUserStore, useRoomsOrdersStore} from "@/console-new/stores";
import {getCompatibleDate, getLocalDateFromServer} from "@/shared/helpers/dates";
import {getGroupIdentifier, getOrderStatus, normalizeOrders} from "@/console-new/helpers/orders";
import {ordersPrinting} from "@/console-new/helpers/escpos";
import {getNative, isAndroid, isNative} from "@/shared/helpers/webapp";

export const useOrdersStore = defineStore('orders', {
	state: () => ({
		orderGroups: [],
		selectedGroupId: null,
		filterRooms: true,
		filterCounter: true,
		filterTakeaway: true,
		filterDelivery: true,
		count: 0,
		countGroups: 0,
		countCounter: 0,
		countRooms: 0,
		countTakeaway: 0,
		countDelivery: 0,
		showCompleted: false,
		search: null,
		searchLoading: false,
		onlyMyOrders: getOnlyMyOrdersValue(),
		page: 0,
		pageSize: 25,
		loaded: false,
		lastTimeSeen: null,
		audioNewOrder: null,
		showMobileOrderSummary: false,
		transitioningMobileOrderSummary: false,
		isUsingOldQrs: false,
		deliveryOrderInfo: null,
		sendReceiptData: null,
		sendReceiptEpsonData: null,
		ctaIsDueForOrderId: null,
		ctaIsPaymentPartial: null,
		STATUS: {
			unconfirmed: "unconfirmed",
			waiting: "waiting",
			confirmed: "confirmed",
			accepted: "accepted",
			preparing: "preparing",
			prepared: "prepared",
			completed: "completed",
			canceled: "canceled",
			failed: "failed"
		},
		tablesToCheck: false
	}),
	getters: {
		ordersUngrouped: state => {
			let orders = [];
			state.orderGroups.forEach(group => {
				orders = orders.concat(group.orders);
			});
			return orders;
		},
		selectedOrderGroup: state => {
			if(state.selectedGroupId) {
				return state.orderGroups.find(group => group.groupIdentifier === state.selectedGroupId);
			} else {
				return null;
			}
		},
		totalOrderCards: state => {
			let result = 0;
			if(state.showCompleted || state.search) {
				if(state.filterDelivery) {
					result += state.countDelivery;
				}
				if(state.filterTakeaway) {
					result += state.countTakeaway;
				}
				if(state.filterRooms) {
					result += state.countRooms;
				}
				if(state.filterCounter) {
					result += state.countCounter;
				}
			} else {
				result = state.countGroups;
			}
			return result;
		},
	},
	actions: {
		resetData() {
			this.orderGroups = [];
			this.selectedGroupId = null;
			this.count = 0;
			this.countGroups = 0;
			this.countRooms = 0;
			this.countCounter = 0;
			this.countTakeaway = 0;
			this.countDelivery = 0;
			this.loaded = false;
			this.lastTimeSeen = null;
			this.isUsingOldQrs = false;
		},
		activateMobileOrderSummary() {
			this.transitioningMobileOrderSummary = true;
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.showMobileOrderSummary = true;
				});
			});
		},
		deactivateMobileOrderSummary() {
			this.showMobileOrderSummary = false;
			setTimeout(() => {
				this.transitioningMobileOrderSummary = false;
			}, 500);
		},
		playNewOrderSound() {
			if(!isAndroid()) {
				const userStore = useUserStore();
				if(!userStore.currBusiness.notification_sound) {
					return;
				}
				if(this.audioNewOrder) {
					this.audioNewOrder.play().catch(() => {
						console.warn("Audio play not allowed");
					});
				} else {
					this.audioNewOrder = (new Audio('https://img.qromo.io/audio/'+userStore.currBusiness.notification_sound+'.mp3'));
					this.audioNewOrder.play().catch(() => {
						console.warn("Audio play not allowed");
					});
				}
			}
		},
		refreshUncompletedOrders(callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}
			let requestIdentifier = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterCounter+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.onlyMyOrders;
			API.init().getOrdersNew(userStore.currBusinessId, this.filterRooms ? 1 : 0, this.filterCounter ? 1 : 0, this.filterDelivery ? 1 : 0, this.filterTakeaway ? 1 : 0, this.onlyMyOrders ? 1 : 0, this.pageSize, this.page, (response) => {

				if(response && response.result === "OK" && response.orders) {
					if(this.lastTimeSeen === null || getCompatibleDate(response.time) > getCompatibleDate(this.lastTimeSeen)) {
						this.lastTimeSeen = response.time;
					}

					let requestIdentifierAfter = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterCounter+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.onlyMyOrders;
					if(requestIdentifier !== requestIdentifierAfter) {
						callback && callback(false);
						return;
					}

					if(response.orders.length > 0) {
						this.orderGroups = normalizeOrders(response.orders, response.items, response.items_products, response.addresses, response.rates, response.payments, response.plugins, true, false, false, response?.rooms);
						if(this.orderGroups.length > 0) {
							if(this.selectedGroupId) {
								let group = this.orderGroups.find(group => group.groupIdentifier === this.selectedGroupId);
								if(!group) {
									this.selectedGroupId = this.orderGroups[0].groupIdentifier;
								}
							} else {
								this.selectedGroupId = this.orderGroups[0].groupIdentifier;
							}
						} else {
							this.selectedGroupId = null;
							if(this.showMobileOrderSummary) {
								this.deactivateMobileOrderSummary();
							}
						}
					} else {
						this.orderGroups = [];
						this.selectedGroupId = null;
						this.isUsingOldQrs = false;
						if(this.showMobileOrderSummary) {
							this.deactivateMobileOrderSummary();
						}
					}
					this.count = Number(response.count);
					this.countGroups = Number(response.count_groups);
					this.countRooms = Number(response.count_rooms);
					this.countCounter = Number(response.count_counter);
					this.countTakeaway = Number(response.count_takeaway);
					this.countDelivery = Number(response.count_delivery);
					if(isNative()) {
						ordersPrinting(this.ordersUngrouped);
					}
					callback && callback(response.orders);
				} else {
					callback && callback(false);
				}
			});
		},
		refreshCompletedOrders(callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}
			let requestIdentifier = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterCounter+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.onlyMyOrders;
			API.init().getOrdersCompletedNew(userStore.currBusinessId, this.filterRooms ? 1 : 0, this.filterCounter ? 1 : 0, this.filterDelivery ? 1 : 0, this.filterTakeaway ? 1 : 0, this.onlyMyOrders ? 1 : 0, this.pageSize, this.page, userStore.manualCode, (response) => {
				let requestIdentifierAfter = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterCounter+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.onlyMyOrders;
				if(requestIdentifier !== requestIdentifierAfter) {
					callback && callback(false);
					return;
				}
				if(response && response.result === "OK" && response.orders) {

					if(response.orders.length > 0) {
						this.orderGroups = normalizeOrders(response.orders, response.items, response.items_products, response.addresses, response.rates, response.payments, response.plugins, false, false, true, response?.rooms);
						if(this.orderGroups.length > 0) {
							this.selectedGroupId = this.orderGroups[0].groupIdentifier;
						} else {
							this.selectedGroupId = null;
							if(this.showMobileOrderSummary) {
								this.deactivateMobileOrderSummary();
							}
						}
					} else {
						this.orderGroups = [];
						this.selectedGroupId = null;
						this.isUsingOldQrs = false;
						if(this.showMobileOrderSummary) {
							this.deactivateMobileOrderSummary();
						}
					}
					this.count = Number(response.count);
					this.countRooms = Number(response.count_rooms);
					this.countCounter = Number(response.count_counter);
					this.countTakeaway = Number(response.count_takeaway);
					this.countDelivery = Number(response.count_delivery);

					callback && callback(response.orders);

				} else {
					callback && callback(false);
				}
			});
		},
		refreshSearchOrders(callback = null) {
			let userStore = useUserStore();
			if(!userStore.currBusinessId) {
				return;
			}
			let requestIdentifier = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterCounter+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.onlyMyOrders;
			API.init().searchOrdersNew(userStore.currBusinessId, this.search, this.showCompleted ? 1 : 0, this.filterRooms ? 1 : 0, this.filterCounter ? 1 : 0, this.filterDelivery ? 1 : 0, this.filterTakeaway ? 1 : 0, this.onlyMyOrders ? 1 : 0, this.pageSize, this.page, userStore.manualCode, (response) => {
				let requestIdentifierAfter = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterCounter+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.onlyMyOrders;
				if(requestIdentifier !== requestIdentifierAfter) {
					callback && callback(false);
					return;
				}
				if(response && response.result === "OK" && response.orders) {

					if(response.orders.length > 0) {
						let ordersSorted = response.orders.map(o => Object.assign({priority: o.priority}, o.order));
						this.orderGroups = normalizeOrders(ordersSorted, response.items, response.items_products, response.addresses, response.rates, response.payments, response.plugins, true, true, true, response?.rooms);

						if(this.orderGroups.length > 0) {
							this.selectedGroupId = this.orderGroups[0].groupIdentifier;
						} else {
							this.selectedGroupId = null;
							if(this.showMobileOrderSummary) {
								this.deactivateMobileOrderSummary();
							}
						}
					} else {
						this.orderGroups = [];
						this.selectedGroupId = null;
						this.isUsingOldQrs = false;
						if(this.showMobileOrderSummary) {
							this.deactivateMobileOrderSummary();
						}
					}
					this.count = Number(response.count);
					this.countRooms = Number(response.count_rooms);
					this.countCounter = Number(response.count_counter);
					this.countTakeaway = Number(response.count_takeaway);
					this.countDelivery = Number(response.count_delivery);
					callback && callback(response.orders);

				} else {
					callback && callback(false);
				}
			});
		},
		refreshOrders(callback = null) {
			if(this.search) {
				this.refreshSearchOrders(callback);
			} else {
				if(this.showCompleted) {
					this.refreshCompletedOrders(callback);
				} else {
					this.refreshUncompletedOrders(callback);
				}
			}
		},
		getOrdersUpdates(callback) {

			let callbackCalled = false;
			try {

				let userStore = useUserStore();
				let roomsOrdersStore = useRoomsOrdersStore();
				if(!userStore.currBusinessId) {
					callback && !callbackCalled && callback(false);
					callbackCalled = true;
					return;
				}
				let trackingOrderIds = [];
				if(!this.showCompleted && !this.search && this.orderGroups && this.orderGroups.length > 0) {
					this.orderGroups.forEach(group => {
						group.orders.forEach(order => {
							trackingOrderIds.push(order.order_id);
						});
					});
				}
				const epsonStore = useEpsonStore();
				const requestFiscal = epsonStore.hasFiscalPrinterAutoMode;
				const requestTableCalls = roomsOrdersStore.enabledTableCalls();
				let requestIdentifier = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.lastTimeSeen+"_"+requestFiscal+"_"+this.onlyMyOrders;
				API.init().getOrdersUpdates(userStore.currBusinessId, JSON.stringify(trackingOrderIds), this.lastTimeSeen, requestFiscal, requestTableCalls, this.onlyMyOrders,  (response) => {

					let requestIdentifierAfter = userStore.currBusinessId+"_"+this.filterRooms+"_"+this.filterDelivery+"_"+this.filterTakeaway+"_"+this.pageSize+"_"+this.page+"_"+this.showCompleted+"_"+this.search+"_"+this.lastTimeSeen+"_"+requestFiscal+"_"+this.onlyMyOrders;
					if(requestIdentifier !== requestIdentifierAfter) {
						console.warn("Request identifier changed, ignoring response");
						console.warn("Before"+requestIdentifier);
						console.warn("After"+requestIdentifierAfter);
						callback && !callbackCalled && callback(false);
						callbackCalled = true;
						return;
					}

					if (response && response.result === "OK") {

						roomsOrdersStore.initRoomsTablesCalls(response.rooms_tables_calls);

						if(this.lastTimeSeen === null || getCompatibleDate(response.time) > getCompatibleDate(this.lastTimeSeen)) {
							this.lastTimeSeen = response.time;
						} else {
							console.warn("Response time is older than lastTimeSeen, forcing reload");
							if(requestFiscal) {
								this.handleFiscalOrders(response);
							}
							this.refreshOrders();
							callback && !callbackCalled && callback(false);
							callbackCalled = true;
							return;
						}

						callback && !callbackCalled && callback(true);
						callbackCalled = true;

						if(response.updates && response.updates.length > 0) {
							this.applyOrdersUpdates(response.updates, true, response.updates_payments, (refreshed) => {
								if(!refreshed) {
									this.handleNewOrders(response);
								}
							});
						} else {
							this.handleNewOrders(response);
						}

						if(requestFiscal) {
							this.handleFiscalOrders(response);
						}

					} else {
						callback && !callbackCalled && callback(false);
						callbackCalled = true;
					}
				}, function () {
					callback && !callbackCalled && callback(false);
					callbackCalled = true;
				});

			} catch (e) {
				callback && !callbackCalled && callback(false);
				callbackCalled = true;
			}

			setTimeout(() => {
				if(!callbackCalled) {
					console.error("Performing force reload, because getOrdersUpdates didn't call callback");
					setTimeout(() => {
						if(!callbackCalled) {
							window.location.reload();
						}
					}, 10000);
				}
			}, 120000);

		},
		handleNewOrders(response) {
			if(response.new_orders && response.new_orders.length > 0) {
				let validNewOrders = this.applyNewOrders(response);
				if(validNewOrders.foundNewOrders) {
					this.playNewOrderSound();

					if(isNative() && getNative().print) {
						ordersPrinting(validNewOrders.newOrders);
					}
				}
				if(validNewOrders.tablesToCheck) {
					this.tablesToCheck = true;
				}
			}
		},
		handleFiscalOrders(response) {
			if(response.fiscal_queue && response.fiscal_queue.orders && response.fiscal_queue.orders.length > 0) {

				let fiscalOrdersGroup = normalizeOrders(response.fiscal_queue.orders, response.fiscal_queue.items, response.fiscal_queue.items_products, response.fiscal_queue.addresses, response.fiscal_queue.rates, response.fiscal_queue.payments, response.fiscal_queue.plugins, false, !!this.search, true, response?.fiscal_queue?.rooms);
				let orders = [];
				Object.values(fiscalOrdersGroup).forEach(group => {
					orders = orders.concat(group.orders);
				});

				const epsonStore = useEpsonStore();
				epsonStore.printNewOrders(orders);

			}
		},
		isOrderInList(orderId) {
			this.orderGroups.forEach(group=> {
				group.orders.forEach(order=> {
					if(order.order_id === orderId) {
						return true;
					}
				});
			});
			return false;
		},
		wouldNewOrdersBeVisible() {
			let wouldNewOrdersBeVisible = false;
			let isCurrentPageTheLastPage = this.page === Math.floor(this.totalOrderCards / this.pageSize);
			if(isCurrentPageTheLastPage) {
				let hasCurrentPageTheMaxNumberOfCards = this.orderGroups.length === this.pageSize;
				if(!hasCurrentPageTheMaxNumberOfCards) {
					wouldNewOrdersBeVisible = true;
				}
			}
			return wouldNewOrdersBeVisible;
		},
		applyNewOrders(response) {

			let newOrdersGroup = [];
			let tablesToCheck = false;
			response.new_orders.sort((a,b)=> {
				return b.order_id - a.order_id;
			});
			for(let i = response.new_orders.length - 1; i >= 0; i--) {
				let order = response.new_orders[i];
				if(this.isOrderInList(order.order_id)) {
					response.new_orders.splice(i, 1);
				}
				if(order.room_table_id) {
					tablesToCheck = true;
				}
			}

			newOrdersGroup = normalizeOrders(response.new_orders, response.items, response.items_products, response.addresses, response.rates, response.payments, response.plugins, !this.showCompleted, !!this.search, !!this.showCompleted, response?.rooms);

			if(!this.showCompleted && !this.search) {

				for(let i = response.new_orders.length - 1; i >= 0; i--) {
					let order = response.new_orders[i];
					if(order.status === this.STATUS.completed) {
						continue;
					}
					this.count++;
					if(order.order_mode === "qr") {
						this.countRooms++;
					} else if(order.order_mode === "counter") {
						this.countCounter++;
					} else if(order.order_mode === "delivery") {
						this.countDelivery++;
					} else if(order.order_mode === "takeaway") {
						this.countTakeaway++;
					}
				}

				let wouldNewOrdersBeVisible = this.wouldNewOrdersBeVisible();

				for(let i = 0; i < newOrdersGroup.length; i++) {
					let newGroup = newOrdersGroup[i];
					if(newGroup.status === this.STATUS.completed) {
						continue;
					}
					let existingGroup = this.orderGroups.find(g => g.groupIdentifier === newGroup.groupIdentifier);
					if(existingGroup) {
						existingGroup.orders = existingGroup.orders.concat(newGroup.orders);
						existingGroup.orders.sort((a, b) => {
							return b.order_id - a.order_id;
						});
						if(this.selectedGroupId !== existingGroup.groupIdentifier) {
							existingGroup.alert = true;
						} else {
							let ordersSeen = localStorage.getItem('ordersSeen');
							ordersSeen = ordersSeen ? JSON.parse(ordersSeen) : {};
							newGroup.orders.forEach((order) => {
								if(!ordersSeen.hasOwnProperty(order.order_id)) {
									ordersSeen[order.order_id] = true;
								}
							});
							if(Math.random() > 0.95 && Object.keys(ordersSeen).length > 10000) {
								let newKeys = {};
								this.orderGroups.forEach((group) => {
									group.orders.forEach((order) => {
										newKeys[order.order_id] = true;
									});
								});
								ordersSeen = newKeys;
							}
							localStorage.setItem('ordersSeen', JSON.stringify(ordersSeen));
						}
					} else {
						if(wouldNewOrdersBeVisible) {
							this.orderGroups.push(newGroup);
						}
						this.countGroups++;
					}
				}

				if(wouldNewOrdersBeVisible) {

					if(this.selectedGroupId === null && newOrdersGroup.length > 0) {
						this.selectedGroupId = newOrdersGroup[0].groupIdentifier;
					}

				}

			}

			let orders = [];
			newOrdersGroup.forEach(group => {
				orders = orders.concat(group.orders);
			});
			return {
				foundNewOrders: response.new_orders.length > 0,
				tablesToCheck: tablesToCheck,
				newOrders: orders
			};
		},
		applyOrdersUpdates(updates, normalize = true, paymentsUpdates = null, callback = null) {

			let ordersUpdatesObj = {};
			updates.forEach(update=> {
				if(normalize) {
					if(update.hasOwnProperty("order_id")) {
						update.order_id = Number(update.order_id);
					} else {
						return;
					}
					if(update.hasOwnProperty("sms_sent")) {
						update.sms_sent = update.sms_sent === "1";
					}
					if(update.hasOwnProperty("fiscal_receipt")) {
						update.fiscal_receipt = update.fiscal_receipt === "1";
					}
					if(update.hasOwnProperty("fiscal_request")) {
						update.fiscal_request = update.fiscal_request === "1";
					}
					if(update.hasOwnProperty("paid")) {
						update.paid = update.paid === "1";
					}
					if(update.hasOwnProperty("receipt_id")) {
						update.receipt_id = update.receipt_id ? Number(update.receipt_id) : null;
					}
					if(update.hasOwnProperty("epson_receipt_id")) {
						update.epson_receipt_id = update.epson_receipt_id ? Number(update.epson_receipt_id) : null;
					}
					if(paymentsUpdates && paymentsUpdates.length > 0) {
						update.payments = [];
					}
					if(update.hasOwnProperty("course")) {
						update.course = Number(update.course);
					}
					if(update.hasOwnProperty("date_last_edit")) {
						update.date_last_edit = update.date_last_edit ? getLocalDateFromServer(update.date_last_edit) : null;
					}
				}

				ordersUpdatesObj[update.order_id] = update;
			});

			if(paymentsUpdates && paymentsUpdates.length > 0) {
				paymentsUpdates.forEach(payment => {
					payment.fiscal_request = payment.fiscal_request === "1";
					payment.fiscal_receipt = payment.fiscal_receipt === "1";
					payment.receipt_id = payment.receipt_id ? Number(payment.receipt_id) : null;
					payment.epson_receipt_id = payment.epson_receipt_id ? Number(payment.epson_receipt_id) : null;
					ordersUpdatesObj[payment.order_id].payments.push(payment);
				});
			}

			let forceRefresh = false;
			groupLoop: for(let groupindex = this.orderGroups.length-1; groupindex >= 0; groupindex--) {

				for(let orderIndex = 0; orderIndex < this.orderGroups[groupindex].orders.length; orderIndex++) {

					let order = this.orderGroups[groupindex].orders[orderIndex];
					if(ordersUpdatesObj.hasOwnProperty(order.order_id)) {

						let update = ordersUpdatesObj[order.order_id];
						let recalcStatus = false;
						if(update.hasOwnProperty("sms_sent") && update.sms_sent !== order.sms_sent) {
							this.orderGroups[groupindex].orders[orderIndex].sms_sent = update.sms_sent;
							if(order.groupLeader) {
								this.orderGroups[groupindex].sms_sent = update.sms_sent;
							}
						}
						if(update.hasOwnProperty("fiscal_receipt") && update.fiscal_receipt !== order.fiscal_receipt) {
							this.orderGroups[groupindex].orders[orderIndex].fiscal_receipt = update.fiscal_receipt;
						}
						if(update.hasOwnProperty("fiscal_request") && update.fiscal_request !== order.fiscal_request) {
							this.orderGroups[groupindex].orders[orderIndex].fiscal_request = update.fiscal_request;
						}
						if(update.hasOwnProperty("status") && update.status !== order.status) {
							if(this.selectedGroupId === order.groupIdentifier) {
								if(update.status !== "completed") {
									this.selectedGroupId = getGroupIdentifier(order, !this.showCompleted, update.status);
								} else {
									if(groupindex+1 < this.orderGroups.length) {
										this.selectedGroupId = this.orderGroups[groupindex+1].groupIdentifier;
									} else {
										this.selectedGroupId = null;
									}
									if(this.showMobileOrderSummary) {
										this.deactivateMobileOrderSummary();
									}
									if(order.room_table_id) {
										this.tablesToCheck = true;
									}
								}
							}
							forceRefresh = true;
							break groupLoop;
						}
						if(update.hasOwnProperty("paid") && update.paid !== order.paid) {
							this.orderGroups[groupindex].orders[orderIndex].paid = update.paid;
							if(order.groupLeader) {
								this.orderGroups[groupindex].paid = update.paid;
							}
						}
						if(update.hasOwnProperty("receipt_id") && update.receipt_id !== order.receipt_id) {
							this.orderGroups[groupindex].orders[orderIndex].receipt_id = update.receipt_id;
						}
						if(update.hasOwnProperty("epson_receipt_id") && update.epson_receipt_id !== order.epson_receipt_id) {
							this.orderGroups[groupindex].orders[orderIndex].epson_receipt_id = update.epson_receipt_id;
						}
						if(update.hasOwnProperty("course") && update.course !== order.course) {
							this.orderGroups[groupindex].orders[orderIndex].course = update.course;
						}
						if(update.hasOwnProperty("type") && update.type !== order.type) {
							forceRefresh = true;
							break groupLoop;
						}
						if(update.hasOwnProperty("date_last_edit") && ((order.date_last_edit === null && update.date_last_edit !== null) || (order.date_last_edit !== null && update.date_last_edit !== null && update.date_last_edit.getTime() !== order.date_last_edit.getTime()))) {
							forceRefresh = true;
							break groupLoop;
						}
						if(update.hasOwnProperty("payments")) {
							let updatePaymentsIds = update.payments.map(payment => payment.order_payment_id);
							let orderPaymentsIds = order.payments.map(payment => payment.order_payment_id);

							const containsAll = (arr1, arr2) => arr2.every(arr2Item => arr1.includes(arr2Item))
							const sameMembers = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);

							if(!sameMembers(updatePaymentsIds, orderPaymentsIds)) {
								forceRefresh = true;
								break groupLoop;
							} else {
								let updatePaymentsMap = {};
								update.payments.forEach(payment => {
									updatePaymentsMap[payment.order_payment_id] = payment;
								});
								for(let i = 0; i < order.payments.length; i++) {
									let payment = order.payments[i];
									if(updatePaymentsMap.hasOwnProperty(payment.order_payment_id)) {
										let updatePayment = updatePaymentsMap[payment.order_payment_id];
										if(updatePayment.fiscal_request !== payment.fiscal_request) {
											this.orderGroups[groupindex].orders[orderIndex].payments[i].fiscal_request = updatePayment.fiscal_request;
										}
										if(updatePayment.fiscal_receipt !== payment.fiscal_receipt) {
											this.orderGroups[groupindex].orders[orderIndex].payments[i].fiscal_receipt = updatePayment.fiscal_receipt;
										}
										if(updatePayment.receipt_id !== payment.receipt_id) {
											this.orderGroups[groupindex].orders[orderIndex].payments[i].receipt_id = updatePayment.receipt_id;
										}
										if(updatePayment.epson_receipt_id !== payment.epson_receipt_id) {
											this.orderGroups[groupindex].orders[orderIndex].payments[i].epson_receipt_id = updatePayment.epson_receipt_id;
										}
									}
								}
							}
						}
						if(recalcStatus) {

							let status = getOrderStatus(order);
							this.orderGroups[groupindex].orders[orderIndex].paymentMethodName = status.paymentMethodName;
							this.orderGroups[groupindex].orders[orderIndex].statusColor = status.statusColor;
							this.orderGroups[groupindex].orders[orderIndex].paymentColor = status.paymentColor;
							if(order.groupLeader) {
								this.orderGroups[groupindex].paymentMethodName = status.paymentMethodName;
								this.orderGroups[groupindex].statusColor = status.statusColor;
								this.orderGroups[groupindex].paymentColor = status.paymentColor;
							}
						}
					}
				}

			}

			if(forceRefresh) {
				this.refreshOrders(() => {
					callback && callback(true);
				});
			} else {
				callback && callback(false);
			}

		},
	},
});

function getOnlyMyOrdersValue() {
	let onlyMyOrders = localStorage.getItem('onlyMyOrders');
	return onlyMyOrders === "true";
}
