import { defineStore } from 'pinia';
import {useOrdersStore} from "@/console-new/stores/orders.store";
import {useUserStore} from "@/console-new/stores/user.store";
import API from "@/shared/helpers/API";
import {replaceNonAlphanumeric} from "@/shared/helpers/strings";
import {Epson} from "@/console-new/helpers/epson";
import {orderFiscalizedStatus} from "@/console-new/helpers/orders";
import {useMenuStore, useNativeStore, useRoomsStore, useFunnelStore} from "@/console-new/stores";
import {getNative, isNative} from "@/shared/helpers/webapp";

export const useEpsonStore = defineStore('epson', {
	state: () => ({
		fiscalPrinterFailedSilent: false,
		fiscalPrinterFailed: false,
		printReportResult: null,
	}),
	getters: {
		hasFiscalPrinterAutoMode: state => {
			const nativeStore = useNativeStore();
			return isNative() && nativeStore.fiscalIp !== "" && nativeStore.fiscalMode === 0;
		}
	},
	actions: {
		generateFiscalReceiptApiscontrino(orders, callback) {

			const userStore = useUserStore();
			API.init().generateFiscalReceiptApiscontrino(userStore.currBusinessId, orders, (r) => {
				if(r.result && r.result === "OK" && r.receipt_id) {
					callback && callback(r.receipt_id);
				} else {
					callback && callback(false);
				}
			});

		},
		logError(rawRequest, rawResponse, code, lastCommand, printerStatus) {

			const userStore = useUserStore();
			API.init().logError(userStore.currBusinessId, rawRequest, rawResponse, code, lastCommand, printerStatus, () => {

			});
		},
		printFiscalReceipt(orders, retrying = false) {

			if(!isNative()) {
				return;
			}

			const nativeStore = useNativeStore();
			let excludedStations = nativeStore.excludedStations;

			const userStore = useUserStore();
			let orderIds = orders.map((o) => o.order_id);
			API.init().checkOrdersFiscal(userStore.currBusinessId, orderIds, (r) => {
				if(r.result && r.result === "OK" && r.orders) {

					let orderIdsFound = [];
					const orderMap = {};
					orders.forEach((order) => {
						orderMap[order.order_id] = order;
					});
					r.orders.forEach((order) => {
						let orderFound = orderMap.hasOwnProperty(order.order_id) ? orderMap[order.order_id] : null;
						if(orderFound && order.hasOwnProperty("full_by_single_payment")) {
							orderFound.full_by_single_payment = order.full_by_single_payment;
						}

						if((retrying || order.fiscal_request === "0") && order.fiscal_receipt === "0") {
							//let orderFound = orders.find((o) => o.order_id === Number(order.order_id));
							if(orderFound) {
								if(orderFound.type === "manual") {
									return;
								}

								if(order.type === "multi") {
									return;
								}

								if(orderFound.paid) {
									if(excludedStations.includes(order.payment_user_id ? order.payment_user_id : "null")) {
										return;
									}
									orderIdsFound.push(Number(order.order_id));
									if(order.room_table_id && orderFound?.room?.cover_charge_name) {
										this.generateFiscalReceiptForOrder(orderFound, retrying, orderFound.room.cover_charge_name);
									} else {
										this.generateFiscalReceiptForOrder(orderFound, retrying);
									}
								}

							} else {
								console.warn("Order not found for fiscal receipt: "+order.order_id);
							}
						}
					});

					if(r.payments && r.payments.length > 0) {
						r.payments.forEach((payment) => {
							if(payment.type === "manual") {
								return;
							}
							if(excludedStations.includes(payment.payment_user_id ? payment.payment_user_id : "null")) {
								return;
							}
							if((retrying || payment.fiscal_request === "0") && payment.fiscal_receipt === "0") {
								let ordersFound = [];
								let coverChargeName = null;
								payment.order_ids.forEach((orderId) => {
									let orderFound = orderMap.hasOwnProperty(orderId) ? orderMap[orderId] : null;
									if(orderFound) {
										ordersFound.push(orderFound);
										if(orderFound.room_table_id && !coverChargeName && orderFound?.room?.cover_charge_name) {
											coverChargeName = orderFound.room.cover_charge_name;
										}
									}
								});
								orderIdsFound.push("p"+payment.order_payment_id);
								this.generateFiscalReceiptForPayment(ordersFound, payment, retrying, coverChargeName);
							}
						});
					}

					if(orderIdsFound.length === 0) {
						console.warn("No orders to print fiscal receipt")
						this.fiscalPrinterFailedSilent = true;
					}

				} else {
					console.warn("No orders to print fiscal receipt")
					this.fiscalPrinterFailedSilent = true;
				}
			});

		},
		generateFiscalReceiptForOrder(orderFound, retrying, coverChargeName = null) {
			let email = orderFound.user_email;
			let total = 0;
			let data = "";
			let paymentDesc = "Contanti";
			let paymentMode = "0";

			const menuStore = useMenuStore();
			orderFound.items.forEach(item => {
				let quantity = item.quantity;
				let price = (item.price/100).toFixed(2).replace(".",",");
				total += item.price * item.quantity;
				let desc = replaceNonAlphanumeric(item.name).slice(0, 35);
				let department = 1;
				if(item.menu_item && item.menu_item.department) {
					department = item.menu_item.department;
				} else if(item.category_id) {
					let category = menuStore.categories[item.category_id];
					if(category && category.department) {
						department = category.department;
					}
				}
				data += '<printRecItem operator="1" description="'+desc+'" quantity="'+quantity+'" unitPrice="'+price+'" department="'+department+'" justification="1" />';
				item.products.forEach(product => {
					let quantityProd = product.quantity * item.quantity;
					let priceProd = (product.price/100).toFixed(2).replace(".",",");
					total += product.price * quantityProd;
					let descProd = replaceNonAlphanumeric(product.product_name).slice(0, 35);
					data += '<printRecItem operator="1" description="'+descProd+'" quantity="'+quantityProd+'" unitPrice="'+priceProd+'" department="'+department+'" justification="1" />';
				});
			});

			let userStore = useUserStore();
			let additionalCosts = orderFound.additionalCosts - orderFound.subtotal_delta;
			if(additionalCosts > 0) {
				//Probably need to change the department with a variable
				let nameAddCost;
				if(orderFound.order_mode === "delivery") {
					nameAddCost = "Costi di consegna";
				} else if(orderFound.order_mode === "takeaway") {
					nameAddCost = "Costi aggiuntivi";
				} else {
					nameAddCost = coverChargeName ? replaceNonAlphanumeric(coverChargeName) : "Coperto";
				}
				data += '<printRecItem operator="1" description="'+nameAddCost+'" quantity="1" unitPrice="'+(additionalCosts/100).toFixed(2).replace(".",",")+'" department="1" justification="1" />';
			}

			if(orderFound.items.length === 0 && orderFound.subtotal_delta > 0) {
				data += '<printRecItem operator="1" description="Importo" quantity="1" unitPrice="'+(orderFound.subtotal_delta/100).toFixed(2).replace(".",",")+'" department="1" justification="1" />';
			} else if(orderFound.subtotal_delta !== 0) {
				let discountName = orderFound.subtotal_delta > 0 ? "Maggiorazione" : "Sconto";
				let discountType = orderFound.subtotal_delta > 0 ? "6" : "1";
				let discountAmount = orderFound.subtotal_delta > 0 ? orderFound.subtotal_delta : -orderFound.subtotal_delta;
				let discountFormatted = (discountAmount/100).toFixed(2).replace(".",",");
				data += '<printRecSubtotalAdjustment Ope="1" Text="'+discountName+'" Type="'+discountType+'" Amount="'+discountFormatted+'" Dep="0" Just="2" />';
			}


			let paymentInfo = this.getEpsonPaymentInfoFromType(orderFound.type);
			paymentDesc = paymentInfo.paymentDesc;
			paymentMode = paymentInfo.paymentMode;

			if(orderFound.type === "multi") {
				if(orderFound.payments) {

					let grandTotal = total+additionalCosts+orderFound.subtotal_delta;
					for(let i = 0; i < orderFound.payments.length; i++) {
						if(orderFound.payments[i].amount <= 0) {
							continue;
						}
						paymentInfo = this.getEpsonPaymentInfoFromType(orderFound.payments[i].type);
						paymentDesc = paymentInfo.paymentDesc;
						paymentMode = paymentInfo.paymentMode;
						total = ((orderFound.payments[i].amount)/100).toFixed(2).replace(".",",");
						grandTotal -= orderFound.payments[i].amount;
						data += '<printRecTotal operator="1" description="'+paymentDesc+'" payment="'+total+'" paymentType="'+paymentMode+'" index="3" justification="1" />';
					}
					if(grandTotal > 0) {
						paymentDesc = "Pagamenti multipli";
						total = ((grandTotal)/100).toFixed(2).replace(".",",");
						data += '<printRecTotal operator="1" description="'+paymentDesc+'" payment="'+total+'" paymentType="'+paymentMode+'" index="3" justification="1" />';
					}

				} else {
					paymentDesc = "Pagamenti multipli";
					total = ((total+additionalCosts+orderFound.subtotal_delta)/100).toFixed(2).replace(".",",");
					data += '<printRecTotal operator="1" description="'+paymentDesc+'" payment="'+total+'" paymentType="'+paymentMode+'" index="3" justification="1" />';
				}
			} else {
				total = ((total+additionalCosts+orderFound.subtotal_delta)/100).toFixed(2).replace(".",",");
				data += '<printRecTotal operator="1" description="'+paymentDesc+'" payment="'+total+'" paymentType="'+paymentMode+'" index="3" justification="1" />';
			}

			if(orderFound.orderNumberDayFormatted) {
				data += '<printRecMessage  operator="1" messageType="3" index="1" font="4" message="Ordine '+orderFound.orderNumberDayFormatted+'" />';
			}

			const nativeStore = useNativeStore();
			if(nativeStore.isQrFiscalReceipt && orderFound.id_encrypted && userStore.currBusiness.link) {
				let link = (LOCAL ? "http://" : "https://") + userStore.currBusiness.link + "." + HOST + "/order/?id="+orderFound.id_encrypted;
				data += '<printBarCode operator="1" qRCodeAlignment="1" qRCodeSize ="7" qRCodeErrorCorrection="1" codeType="QRCODE2" code="'+link+'" />';
			}

			Epson.addToQueue({
				ids: JSON.stringify([Number(orderFound.order_id)]),
				data: data,
				email: email
			}, retrying);
		},
		generateFiscalReceiptForPayment(ordersFound, payment, retrying, coverChargeName = null) {
			if(ordersFound.length === 0) {
				return;
			}
			let email = ordersFound[0].user_email;
			let data = "";
			let paymentDesc = "Contanti";
			let paymentMode = "0";

			let departmentsMap = {};
			let explicitItemsTotal = 0;
			let explicitAddCosts = 0;
			let explicitCover = 0;

			const menuStore = useMenuStore();
			ordersFound.forEach(order => {
				const fullBySinglePayment = order.hasOwnProperty("full_by_single_payment") && order.full_by_single_payment;

				if(fullBySinglePayment) {
					explicitAddCosts += order.delivery_costs_value + order.takeaway_costs_value + order.subtotal_delta;
					explicitCover += order.cover_charge_value*order.cover_charge_num;
				}

				order.items.forEach(item => {
					let department = 1;
					if(item.menu_item && item.menu_item.department) {
						department = item.menu_item.department;
					} else if(item.category_id) {
						let category = menuStore.categories[item.category_id];
						if(category && category.department) {
							department = category.department;
						}
					}

					if(fullBySinglePayment) {

						let quantity = item.quantity;
						let price = (item.price/100).toFixed(2).replace(".",",");
						let desc = replaceNonAlphanumeric(item.name).slice(0, 35);

						explicitItemsTotal += item.price * item.quantity;
						data += '<printRecItem operator="1" description="'+desc+'" quantity="'+quantity+'" unitPrice="'+price+'" department="'+department+'" justification="1" />';
						item.products.forEach(product => {
							let quantityProd = product.quantity * item.quantity;
							let priceProd = (product.price/100).toFixed(2).replace(".",",");
							let descProd = replaceNonAlphanumeric(product.product_name).slice(0, 35);
							data += '<printRecItem operator="1" description="'+descProd+'" quantity="'+quantityProd+'" unitPrice="'+priceProd+'" department="'+department+'" justification="1" />';

							explicitItemsTotal += product.price * quantityProd;
						});

					} else {

						if(!departmentsMap[department]) {
							departmentsMap[department] = 0;
						}
						departmentsMap[department] += item.price * item.quantity;
						item.products.forEach(product => {
							departmentsMap[department] += (product.price * product.quantity) * item.quantity;
						});

					}
				});

			});

			let amount = Number(payment.amount);
			if(amount <= 0) {
				return;
			}
			let departmentsTotal = Object.values(departmentsMap).reduce((a, b) => a + b, 0);
			let remainingAmount = amount;
			if(departmentsTotal > 0) {
				for(let department in departmentsMap) {
					let price = (departmentsMap[department]/departmentsTotal) * amount;
					remainingAmount -= price;
					price = (price/100).toFixed(2).replace(".",",");
					data += '<printRecItem operator="1" description="Quota a Persona" quantity="1" unitPrice="'+price+'" department="'+department+'" justification="1" />';
				}
			}
			remainingAmount -= explicitItemsTotal;
			remainingAmount -= explicitAddCosts;
			remainingAmount -= explicitCover;
			if(remainingAmount >= 1) {
				let department = 1;
				let price = (remainingAmount/100).toFixed(2).replace(".",",");
				data += '<printRecItem operator="1" description="Quota a Persona" quantity="1" unitPrice="'+price+'" department="'+department+'" justification="1" />';
			}

			let paymentInfo = this.getEpsonPaymentInfoFromType(payment.type);
			paymentDesc = paymentInfo.paymentDesc;
			paymentMode = paymentInfo.paymentMode;

			const userStore = useUserStore();

			if(explicitCover > 0) {
				//Probably need to change the department with a variable
				let explicitCoverName = coverChargeName ? replaceNonAlphanumeric(coverChargeName) : "Coperto";
				explicitCoverName = explicitCoverName || "Coperto";
				data += '<printRecItem operator="1" description="'+explicitCoverName+'" quantity="1" unitPrice="'+(explicitCover/100).toFixed(2).replace(".",",")+'" department="1" justification="1" />';
			}

			if(explicitAddCosts !== 0) {
				let discountName = explicitAddCosts > 0 ? "Maggiorazione" : "Sconto";
				let discountType = explicitAddCosts > 0 ? "6" : "1";
				let discountAmount = explicitAddCosts > 0 ? explicitAddCosts : -explicitAddCosts;
				let discountFormatted = (discountAmount/100).toFixed(2).replace(".",",");
				data += '<printRecSubtotalAdjustment Ope="1" Text="'+discountName+'" Type="'+discountType+'" Amount="'+discountFormatted+'" Dep="0" Just="2" />';
			}

			let amountString = (amount/100).toFixed(2).replace(".",",");
			data += '<printRecTotal operator="1" description="'+paymentDesc+'" payment="'+amountString+'" paymentType="'+paymentMode+'" index="3" justification="1" />';

			let orderPart = null;
			if(ordersFound.length > 1) {
				orderPart = "Ordini ";
				ordersFound.forEach((order, index) => {
					orderPart += order.orderNumberDayFormatted;
					if(index < ordersFound.length-1) {
						orderPart += ", ";
					}
				});
			} else {
				if(ordersFound[0].orderNumberDayFormatted) {
					orderPart = "Ordine "+ordersFound[0].orderNumberDayFormatted;
				}
			}
			data += '<printRecMessage  operator="1" messageType="3" index="1" font="4" message="'+orderPart+' - Pagamento #'+payment.payment_id+'" />';

			const nativeStore = useNativeStore();
			if(nativeStore.isQrFiscalReceipt && ordersFound[0].id_encrypted && userStore.currBusiness.link) {
				let link = (LOCAL ? "http://" : "https://") + userStore.currBusiness.link + "." + HOST + "/order/?id="+ordersFound[0].id_encrypted;
				data += '<printBarCode operator="1" qRCodeAlignment="1" qRCodeSize ="7" qRCodeErrorCorrection="1" codeType="QRCODE2" code="'+link+'" />';
			}

			Epson.addToQueue({
				ids: JSON.stringify(["p"+Number(payment.payment_id)]),
				data: data,
				email: email
			}, retrying);
		},
		customFiscalReceipt(description, amount, paymentType) {

			if(!isNative()) {
				return;
			}

			let data = "";
			let paymentDesc = "Contanti";
			let paymentMode = "0";

			let amountString = (amount/100).toFixed(2).replace(".",",");
			data += '<printRecItem operator="1" description="'+description+'" quantity="1" unitPrice="'+amountString+'" department="1" justification="1" />';

			let paymentInfo = this.getEpsonPaymentInfoFromType(paymentType);
			paymentDesc = paymentInfo.paymentDesc;
			paymentMode = paymentInfo.paymentMode;


			data += '<printRecTotal operator="1" description="'+paymentDesc+'" payment="'+amountString+'" paymentType="'+paymentMode+'" index="3" justification="1" />';

			if(isNative()) {
				getNative().printFiscalReceipt("[]", data, null);
			}

		},
		getEpsonPaymentInfoFromType(type) {
			let paymentDesc = "Contanti";
			let paymentMode = "0";
			if(type === "stripe") {
				paymentDesc = "Stripe, carta";
				paymentMode = "2";
			} else if(type === "klarna") {
				paymentDesc = "Stripe, klarna";
				paymentMode = "2";
			} else if(type === "satispay") {
				paymentDesc = "Satispay";
				paymentMode = "2";
			} else if(type === "btc") {
				paymentDesc = "Bitcoin";
				paymentMode = "2";
			} else if(type === "ticket") {
				paymentDesc = "Ticket";
				paymentMode = "3";
			} else if(type === "paylater") {
				paymentDesc = "Non riscosso";
				paymentMode = "5";
			} else if(type === "check") {
				paymentDesc = "Assegno";
				paymentMode = "1";
			} else if(type === "wire") {
				paymentDesc = "Bonifico";
				paymentMode = "1";
			} else if(type === "external") {
				paymentDesc = "Pagamento esterno";
				paymentMode = "2";
			}
			return {
				paymentDesc,
				paymentMode
			}
		},
		fiscalReceiptSuccess(orderIds, receiptData) {
			const userStore = useUserStore();
			const ordersStore = useOrdersStore();
			API.init().setOrdersFiscal(userStore.currBusinessId, orderIds, receiptData, (r) => {
				console.log("SET ORDERS FISCAL", JSON.stringify(r));
				if(r.result && r.result === "OK") {
					ordersStore.ordersUngrouped.forEach((order) => {
						orderIds.forEach((orderId) => {
							if(order.order_id === orderId) {
								order.fiscal_receipt = true;
							}
						});
					});
				}
			});
		},
		setOrdersFiscalSent(orderIds, callback) {
			const userStore = useUserStore();
			const ordersStore = useOrdersStore();

			let currentBusinessAction = useFunnelStore().currStep?.actionId;
			let sendingBusinessAction = currentBusinessAction && currentBusinessAction === "emit_receipt" ? currentBusinessAction : null;
			let sendZarazStepEvent = false;
			if(useFunnelStore()?.currStep?.trackEventId === 'step6_allinone' && sendingBusinessAction === "emit_receipt") {
				sendZarazStepEvent = true;
			}

			API.init().setOrdersFiscalSent(userStore.currBusinessId, orderIds, sendingBusinessAction, (r) => {
				if(r.result && r.result === "OK") {
					ordersStore.ordersUngrouped.forEach((order) => {
						orderIds.forEach((orderId) => {
							if(order.order_id === orderId) {
								order.fiscal_request = true;
							}
						});
						order.payments.forEach((payment) => {
							orderIds.forEach((orderId) => {
								if("i"+payment.order_payment_id === orderId) {
									payment.fiscal_request = true;
								}
							});
						});
					});
					if(!!sendingBusinessAction) {
						useFunnelStore().businessActions = useFunnelStore().businessActions?.length ? [...useFunnelStore().businessActions, sendingBusinessAction] : [sendingBusinessAction];
					}
					if(sendZarazStepEvent) {
						useFunnelStore().sendZarazStepEvent('step6_allinone');
					}
					callback(true);
				} else {
					callback(false);
				}
			});
		},
		checkFiscalLastDay(callback) {
			const userStore = useUserStore();
			API.init().getUnfiscalizedOrdersLastDay(userStore.currBusinessId, (r) => {
				if(r.result && r.result === "OK") {
					callback(r);
				} else {
					callback(false);
				}
			});
		},
		printNewOrders(orders) {

			if(isNative() && orders.length > 0) {
				const nativeStore = useNativeStore();
				if(nativeStore.fiscalIp !== "" && nativeStore.fiscalMode === 0) {
					let ordersFiscal = orders.filter((o) => orderFiscalizedStatus(o) !== "fiscalized");
					if(ordersFiscal.length > 0) {
						this.printFiscalReceipt(ordersFiscal);
					}
				}
			}

		}
	}
});







